import {
  PROPERTY_IMAGE_UPLOAD_FAIL,
  PROPERTY_IMAGE_UPLOAD_REQUEST,
  PROPERTY_IMAGE_UPLOAD_SUCCESS,
} from "../constants/uploadpropimages";

const initialStateProperties = {
  loading: false,
  imageUrl: [],
  error: null,
};

export const imageUploadReducer = (state = initialStateProperties, action) => {
  switch (action.type) {
    case PROPERTY_IMAGE_UPLOAD_REQUEST:
      return { ...state, loading: true, imageUrl: [] };
    case PROPERTY_IMAGE_UPLOAD_SUCCESS:
      return { ...state, loading: false, imageUrl: action.payload };
    case PROPERTY_IMAGE_UPLOAD_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
