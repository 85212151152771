export const LIST_PROPERTIES_REQUEST = 'LIST_PROPERTIES_REQUEST'
export const LIST_PROPERTIES_SUCCESS = 'LIST_PROPERTIES_SUCCESS'
export const LIST_PROPERTIES_FAIL = 'LIST_PROPERTIES_FAIL'

export const PROPERTY_DETAILS_REQUEST = 'PROPERTY_DETAILS_REQUEST'
export const PROPERTY_DETAILS_SUCCESS = 'PROPERTY_DETAILS_SUCCESS'
export const PROPERTY_DETAILS_FAIL = 'PROPERTY_DETAILS_FAIL'

export const FEEDBACK_LIST_REQUEST = 'FEEDBACK_LIST_REQUEST'
export const FEEDBACK_LIST_SUCCESS = 'FEEDBACK_LIST_SUCCESS'
export const FEEDBACK_LIST_FAIL = 'FEEDBACK_LIST_FAIL'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
