import {
  LIST_PROPERTIES_REQUEST,
  LIST_PROPERTIES_SUCCESS,
  LIST_PROPERTIES_FAIL,
  PROPERTY_DETAILS_REQUEST,
  PROPERTY_DETAILS_SUCCESS,
  PROPERTY_DETAILS_FAIL,
} from '../constants/mobileConstants'

const initialStateProperties = {
  loading: false,
  properties: [],
  error: null,
}

const initialStateProperty = {
  loading: false,
  property: {},
  error: null,
}

export const propertiesListReducer = (
  state = initialStateProperties,
  action,
) => {
  switch (action.type) {
    case LIST_PROPERTIES_REQUEST:
      return { ...state, loading: true, properties: [] }
    case LIST_PROPERTIES_SUCCESS:
      return { ...state, loading: false, properties: action.payload }
    case LIST_PROPERTIES_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const mobilePropertyDetailsReducer = (
  state = initialStateProperty,
  action,
) => {
  switch (action.type) {
    case PROPERTY_DETAILS_REQUEST:
      return { ...state, loading: true, property: {} }
    case PROPERTY_DETAILS_SUCCESS:
      return { ...state, loading: false, property: action.payload }
    case PROPERTY_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload, property: {} }

    default:
      return state
  }
}
