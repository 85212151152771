import {
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_DETAILS_REQUEST,
  BLOG_DETAILS_SUCCESS,
  BLOG_DETAILS_FAIL,
  SUBMIT_BLOG_REQUEST,
  SUBMIT_BLOG_SUCCESS,
  SUBMIT_BLOG_FAIL,
  REMOVE_BLOG_REQUEST,
  REMOVE_BLOG_SUCCESS,
  REMOVE_BLOG_FAIL,
  EDIT_BLOG_REQUEST,
  EDIT_BLOG_SUCCESS,
  EDIT_BLOG_FAIL,
} from "../constants/blogConstant";

const initialStateBlogs = {
  loading: false,
  blogs: [],
  error: null,
};

const initialStateBlog = {
  loading: false,
  blog: {},
  error: null,
};

const initialStateBlogSubmit = {
  loading: false,
  status: null,
  success: null,
  error: null,
};

export const blogListReducer = (state = initialStateBlogs, action) => {
  switch (action.type) {
    case BLOG_LIST_REQUEST:
      return { ...state, loading: true, blogs: [] };
    case BLOG_LIST_SUCCESS:
      return { ...state, loading: false, blogs: action.payload };
    case BLOG_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const blogDetailsReducer = (state = initialStateBlog, action) => {
  switch (action.type) {
    case BLOG_DETAILS_REQUEST:
      return { ...state, loading: true, blog: {} };
    case BLOG_DETAILS_SUCCESS:
      return { ...state, loading: false, blog: action.payload };
    case BLOG_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload, property: {} };

    default:
      return state;
  }
};

export const blogSubmitReducer = (state = initialStateBlogSubmit, action) => {
  switch (action.type) {
    case SUBMIT_BLOG_REQUEST:
      return { ...state, loading: true };
    case SUBMIT_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        success: action.payload.success,
      };
    case SUBMIT_BLOG_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export const blogRemoveReducer = (state = initialStateBlog, action) => {
  switch (action.type) {
    case REMOVE_BLOG_REQUEST:
      return { ...state, loading: true, blog: {} };
    case REMOVE_BLOG_SUCCESS:
      return { ...state, loading: false, blog: action.payload };
    case REMOVE_BLOG_FAIL:
      return { ...state, loading: false, error: action.payload, blog: {} };

    default:
      return state;
  }
};

export const blogEditReducer = (state = initialStateBlogSubmit, action) => {
  switch (action.type) {
    case EDIT_BLOG_REQUEST:
      return { ...state, loading: true };
    case EDIT_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
        success: action.payload,
      };
    case EDIT_BLOG_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
