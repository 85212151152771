import {
  LEAD_COMMERCIAL_LIST_REQUEST,
  LEAD_COMMERCIAL_LIST_SUCCESS,
  LEAD_COMMERCIAL_LIST_FAIL,
  LEAD_COMMERCIAL_DETAILS_REQUEST,
  LEAD_COMMERCIAL_DETAILS_SUCCESS,
  LEAD_COMMERCIAL_DETAILS_FAIL,
  SUBMIT_COMMERCIAL_LEAD_REQUEST,
  SUBMIT_COMMERCIAL_LEAD_SUCCESS,
  SUBMIT_COMMERCIAL_LEAD_FAIL,
  EDIT_COMMERCIAL_LEAD_REQUEST,
  EDIT_COMMERCIAL_LEAD_SUCCESS,
  EDIT_COMMERCIAL_LEAD_FAIL,
} from "../constants/tenantCommercialConstant";

const initialStateLeads = {
  loading: false,
  leads: [],
  error: null,
};

const initialStateLead = {
  loading: false,
  lead: {},
  error: null,
};

const initialStateLeadSubmit = {
  loading: false,
  status: null,
  success: null,
  error: null,
};

export const leadCommercialListReducer = (
  state = initialStateLeads,
  action
) => {
  switch (action.type) {
    case LEAD_COMMERCIAL_LIST_REQUEST:
      return { ...state, loading: true, leads: [] };
    case LEAD_COMMERCIAL_LIST_SUCCESS:
      return { ...state, loading: false, leads: action.payload };
    case LEAD_COMMERCIAL_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const leadCommercialDetailsReducer = (
  state = initialStateLead,
  action
) => {
  switch (action.type) {
    case LEAD_COMMERCIAL_DETAILS_REQUEST:
      return { ...state, loading: true, lead: {} };
    case LEAD_COMMERCIAL_DETAILS_SUCCESS:
      return { ...state, loading: false, lead: action.payload };
    case LEAD_COMMERCIAL_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload, lead: {} };

    default:
      return state;
  }
};

export const leadCommercialSubmitReducer = (
  state = initialStateLeadSubmit,
  action
) => {
  switch (action.type) {
    case SUBMIT_COMMERCIAL_LEAD_REQUEST:
      return { ...state, loading: true };
    case SUBMIT_COMMERCIAL_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        success: action.payload.success,
      };
    case SUBMIT_COMMERCIAL_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export const leadCommercialEditReducer = (
  state = initialStateLeadSubmit,
  action
) => {
  switch (action.type) {
    case EDIT_COMMERCIAL_LEAD_REQUEST:
      return { ...state, loading: true };
    case EDIT_COMMERCIAL_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
        success: action.payload,
      };
    case EDIT_COMMERCIAL_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
