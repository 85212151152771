import axios from 'axios'
import { APIlogin, registrationAPI } from '../config/api'

import {
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOAD_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
} from '../constants/authConstants'
import { setAlert } from './alertActions'

import { localStorageToken, localStorageUser } from '../config/localStorage'

//LOGIN USER
export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: LOGIN_REQUEST,
  })
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const res = await axios.post(
      APIlogin,
      {
        email,
        password,
      },
      config,
    )

    console.log('RESULT FROM LOGIN API', res)
    localStorage.setItem('User', res.data.user.name.toUpperCase())

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    dispatch(setAlert('Login Failed', 'danger'))
    dispatch({
      type: LOGIN_FAILURE,
    })
  }
}

//LOGOUT
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  })

  window.location.replace('/')
}

// //Load User
export const loadAccess = (email) => async (dispatch) => {
  dispatch({
    type: USER_LOAD_REQUEST,
  })

  if (
    localStorage.getItem(localStorageToken) &&
    localStorage.getItem(localStorageUser)
  ) {
    //TOKEN is Present in local storage
    let res = {
      isAuthenticated: true,
      user: localStorage.getItem(localStorageUser),
    }
    dispatch({
      type: USER_LOAD_SUCCESS,
      payload: res,
    })
  } else {
    dispatch({
      type: USER_LOAD_FAILURE,
    })
  }
}

export const submitUser = (enquiryObject) => async (dispatch) => {
  let postBody = enquiryObject
  console.log('postBody::::::', postBody)

  try {
    dispatch({ type: SIGNUP_REQUEST })
    const result = await axios.post(registrationAPI, postBody)

    console.log('SUBMIT USER REQUEST', result)

    if (result.status === 200) {
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: {
          status: true,
          success: 'uploaded successfully',
        },
      })
    } else {
      dispatch({
        type: SIGNUP_FAILURE,
        payload: {
          status: false,
          error:
            result.data.inputErrors.exists === true
              ? 'User Record Already Exists'
              : 'Please contact customer centre if issue persists',
        },
      })
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: SIGNUP_FAILURE,
      payload: {
        status: false,
        error: 'Please contact customer centre',
      },
    })
  }
}
