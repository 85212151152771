import {
	COMMERCIAL_LIST_REQUEST,
	COMMERCIAL_LIST_SUCCESS,
	COMMERCIAL_LIST_FAIL,
    SINGLE_USER_PROPERTY_REQUEST,
    SINGLE_USER_PROPERTY_SUCCESS,
    SINGLE_USER_PROPERTY_FAIL,
} from '../constants/commercialConstant'

const initialStateProperties = {
	loading: false,
	properties: [],
	error: null,
}

const initialStateProperty = {
	loading: false,
	property: {},
	error: null,
}

export const commercialPropertyListReducer = (state = initialStateProperties, action) => {
	switch (action.type) {
		case COMMERCIAL_LIST_REQUEST:
			return { loading: true, commercialproperties: []}
		case COMMERCIAL_LIST_SUCCESS:
			return { loading: false, commercialproperties: action.payload}
		case COMMERCIAL_LIST_FAIL:
			return { loading: false, error: action.payload}

		default:
			return state
	}
}

export const singleUserReducer = (	state = initialStateProperty, action) => {
	switch (action.type) {
		case SINGLE_USER_PROPERTY_REQUEST:
			return {...state, loading: true, commercialproperty: {}}
		case SINGLE_USER_PROPERTY_SUCCESS:
			return {...state, loading: false, commercialproperty: action.payload}
		case SINGLE_USER_PROPERTY_FAIL:
			return {...state, loading: false, error: action.payload, commercialproperty: {}}

		default:
			return state
	}
}