export const SUBMIT_ONBOARDING_TENANT_REQUEST =
  'SUBMIT_ONBOARDING_TENANT_REQUEST'
export const SUBMIT_ONBOARDING_TENANT_SUCCESS =
  'SUBMIT_ONBOARDING_TENANT_SUCCESS'
export const SUBMIT_ONBOARDING_TENANT_FAIL = 'SUBMIT_ONBOARDING_TENANT_FAIL'

export const ONBOARDING_TENANT_LIST_REQUEST = 'ONBOARDING_TENANT_LIST_REQUEST'
export const ONBOARDING_TENANT_LIST__SUCCESS = 'ONBOARDING_TENANT_LIST__SUCCESS'
export const ONBOARDING_TENANT_LIST__FAIL = 'ONBOARDING_TENANT_LIST__FAIL'

export const ONBOARDING_TENANT_DETAILS_REQUEST =
  'ONBOARDING_TENANT_DETAILS_REQUEST'
export const ONBOARDING_TENANT_DETAILS__SUCCESS =
  'ONBOARDING_TENANT_DETAILS__SUCCESS'
export const ONBOARDING_TENANT_DETAILS__FAIL = 'ONBOARDING_TENANT_DETAILS__FAIL'

export const DOWNLOAD_TENANT_PAYMENT_REQUEST = 'DOWNLOAD_TENANT_PAYMENT_REQUEST'
export const DOWNLOAD_TENANT_PAYMENT_SUCCESS = 'DOWNLOAD_TENANT_PAYMENT_SUCCESS'
export const DOWNLOAD_TENANT_PAYMENT_FAIL = 'DOWNLOAD_TENANT_PAYMENT_FAIL'

export const DOWNLOAD_TENANT_LIST_REQUEST = 'DOWNLOAD_TENANT_LIST_REQUEST'
export const DOWNLOAD_TENANT_LIST_SUCCESS = 'DOWNLOAD_TENANT_LIST_SUCCESS'
export const DOWNLOAD_TENANT_LIST_FAIL = 'DOWNLOAD_TENANT_LIST_FAIL'

export const DOWNLOAD_TENANTS_PAYMENTS_REQUEST =
  'DOWNLOAD_TENANTS_PAYMENTS_REQUEST'
export const DOWNLOAD_TENANTS_PAYMENTS_SUCCESS =
  'DOWNLOAD_TENANTS_PAYMENTS_SUCCESS'
export const DOWNLOAD_TENANTS_PAYMENTS_FAIL = 'DOWNLOAD_TENANTS_PAYMENTS_FAIL'
