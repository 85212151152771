const API_URL = 'https://zeus.ownerandtenant.com/v1.0'

export const getAllPropertiesAPI = `${API_URL}/api/adminportal/getallproperties`
export const getPropertytDetailsAPI = `${API_URL}/api/adminportal/getproperty`
export const submitSubscriptionEnquiryAPI = `${API_URL}/api/website/emailsubscription`
export const tenantEnquiryAPI = `${API_URL}/api/website/tenantenquiry`
export const submitPropertyFormAPI = `${API_URL}/api/website/ownerenquiry`
export const serviceRequestAPI = `${API_URL}/api/website/servicerequest`
export const contactUsAPI = `${API_URL}/api/website/contactus`
export const getAllBlogsAPI = `${API_URL}/api/adminportal/getallblogs`
export const getBlogDetailsAPI = `${API_URL}/api/adminportal/getblog`
export const propertyimagesupload = `${API_URL}/api/adminportal/property-images-upload`
export const APIlogin = `${API_URL}/auth/user/login`
export const getCommercialPropertiesAPI = `${API_URL}/api/adminportal/getallcommercialproperties`
export const getCommercialProperty = `${API_URL}/api/adminportal/getcommercialproperty`
export const updateCommercialPropertytDetailsAPI = `${API_URL}/api/adminportal/editcommercialpropertydetails`
export const insertResidentialPropertyAPI = `${API_URL}/api/adminportal/addproperty`
export const insertCommercialPropertyAPI = `${API_URL}/api/adminportal/post-commercial-property`

export const updateResidentialPropertyAPI = `${API_URL}/api/adminportal/updateproperty`
export const updateCommercialPropertyAPI = `${API_URL}/api/adminportal/editcommercialpropertydetails`

export const APIGetAllWebEnquiries = `${API_URL}/api/adminportal/getallforms`
export const APIGetAllServiceEnquiries = `${API_URL}/api/adminportal/getallservices`
export const APIUpdateWebEnquiries = `${API_URL}/api/adminportal/updateform`
export const APIUpdateServiceEnquiries = `${API_URL}/api/adminportal/updateservice`

export const submitBlogAPI = `${API_URL}/api/adminportal/createblog`

export const uploadBlogImageAPI = `${API_URL}/api/adminportal/upload-blog-image`

export const removeBlogAPI = `${API_URL}/api/adminportal/remove-blog`

export const editBlogAPI = `${API_URL}/api/adminportal/updateblog`

export const getAllLeadsAPI = `${API_URL}/api/crm/tenant/gettenantleads`
export const getLeadDetailsAPI = `${API_URL}/api/crm/tenant/getlead`

export const submitLeadAPI = `${API_URL}/api/crm/tenant/tenantlead`

export const uploadLeadImageAPI = `${API_URL}/api/crm/tenant/bgv-docs`

export const editLeadAPI = `${API_URL}/api/crm/tenant/editlead`

export const downloadLeadAPI = `${API_URL}/api/crm/tenant/download-tenant-leads`

export const downloadOwnerLeadAPI = `${API_URL}/api/adminportal/download-owner-leads`

//mobile app
export const getListedPropertiesAPI = `${API_URL}/getallproperties`

export const getMobilePropertyDetailsAPI = `${API_URL}/getpropertydetails`

export const getFeedbackList = `${API_URL}/auth/user/getfeedbacklist`
export const getFeedbackDetails = `${API_URL}/auth/user/getfeedbackdetails`

export const getMobileUserList = `${API_URL}/auth/user/getusers`
export const getMobileUserDetails = `${API_URL}/auth/user/getuserdetails`

export const getVisitBookingListAPI = `${API_URL}/api/adminportal/getvisitschedulelist`
export const getVisitBookingDetailsAPI = `${API_URL}/api/adminportal/visitscheduledetails`
export const editVisitBookingAPI = `${API_URL}/api/adminportal/updatevisitschedule`

export const getAllContactsAPI = `${API_URL}/api/adminportal/getallcontacts`

export const registrationAPI = `${API_URL}/auth/user/register`

export const onboardingTenantAPI = `${API_URL}/api/adminportal/tenant-onboarding`

export const onboardedTenantListAPI = `${API_URL}/api/adminportal/get-onboarded-tenant-list`
export const onboardedTenantDetailsAPI = `${API_URL}/api/adminportal/get-onboarded-tenant-details`
export const downloadOnboardedTenantPaymentsAPI = `${API_URL}/api/adminportal/download-tenant-payment-list`
export const downloadOnboardedTenantListAPI = `${API_URL}/api/adminportal/download-onboarded-tenants`
export const downloadOnboardedTenantsPaymentsHistoryAPI = `${API_URL}/api/adminportal/download-rent-payment-list`
