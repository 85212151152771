export const SUBMIT_TENANT_ENQUIRY_REQUEST = 'SUBMIT_TENANT_ENQUIRY_REQUEST'
export const SUBMIT_TENANT_ENQUIRY_SUCCESS = 'SUBMIT_TENANT_ENQUIRY_SUCCESS'
export const SUBMIT_TENANT_ENQUIRY_FAIL = 'SUBMIT_TENANT_ENQUIRY_FAIL'

export const SUBMIT_PROPERTY_FORM_REQUEST = 'SUBMIT_PROPERTY_FORM_REQUEST'
export const SUBMIT_PROPERTY_FORM_SUCCESS = 'SUBMIT_PROPERTY_FORM_SUCCESS'
export const SUBMIT_PROPERTY_FORM_FAIL = 'SUBMIT_PROPERTY_FORM_FAIL'

export const SUBMIT_SERVICE_REQUEST = 'SUBMIT_SERVICE_REQUEST'
export const SUBMIT_SERVICE_SUCCESS = 'SUBMIT_SERVICE_SUCCESS'
export const SUBMIT_SERVICE_FAIL = 'SUBMIT_SERVICE_FAIL'

export const SUBMIT_CONTACTUS_REQUEST = 'SUBMIT_CONTACTUS_REQUEST'
export const SUBMIT_CONTACTUS_SUCCESS = 'SUBMIT_CONTACTUS_SUCCESS'
export const SUBMIT_CONTACTUS_FAIL = 'SUBMIT_CONTACTUS_FAIL'

export const SERVICE_REQUEST = 'SERVICE_REQUEST'

// const values
export const TENANT_ROLE = 'TENANT'
export const ELECTRICIAN_SERVICES = 'ELECTRICIAN_SERVICES'
export const ACSERVICEREPAIR = 'ACSERVICEREPAIR'
