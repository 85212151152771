export const FETCH_TENANT_LEADS_REQUEST = "FETCH_TENANT_LEADS_REQUEST";
export const FETCH_TENANT_LEADS_SUCCESS = "FETCH_TENANT_LEADS_SUCCESS";
export const FETCH_TENANT_LEADS_FAILURE = "FETCH_TENANT_LEADS_FAILURE";

export const FETCH_OWNER_LEADS_REQUEST = "FETCH_OWNER_LEADS_REQUEST";
export const FETCH_OWNER_LEADS_SUCCESS = "FETCH_OWNER_LEADS_SUCCESS";
export const FETCH_OWNER_LEADS_FAILURE = "FETCH_OWNER_LEADS_FAILURE";

export const FETCH_SERVICE_LEADS_REQUEST = "FETCH_SERVICE_LEADS_REQUEST";
export const FETCH_SERVICE_LEADS_SUCCESS = "FETCH_SERVICE_LEADS_SUCCESS";
export const FETCH_SERVICE_LEADS_FAILURE = "FETCH_SERVICE_LEADS_FAILURE";
