export const VISIT_BOOKING_LIST_REQUEST = 'VISIT_BOOKING_LIST_REQUEST'
export const VISIT_BOOKING_LIST_SUCCESS = 'VISIT_BOOKING_LIST_SUCCESS'
export const VISIT_BOOKING_LIST_FAIL = 'VISIT_BOOKING_LIST_FAIL'

export const VISIT_BOOKING_DETAILS_REQUEST = 'VISIT_BOOKING_DETAILS_REQUEST'
export const VISIT_BOOKING_DETAILS_SUCCESS = 'VISIT_BOOKING_DETAILS_SUCCESS'
export const VISIT_BOOKING_DETAILS_FAIL = 'VISIT_BOOKING_DETAILS_FAIL'

export const EDIT_BOOKING_DETAILS_REQUEST = 'EDIT_BOOKING_DETAILS_REQUEST'
export const EDIT_BOOKING_DETAILS_SUCCESS = 'EDIT_BOOKING_DETAILS_SUCCESS'
export const EDIT_BOOKING_DETAILS_FAIL = 'EDIT_BOOKING_DETAILS_FAIL'
