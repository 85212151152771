import React, { Fragment, Suspense, lazy } from 'react'
import { Spinner, Container, Row, Col } from 'react-bootstrap'
import { Route, Switch } from 'react-router-dom'

import SidebarContainer from '../container/SideBarContainer'

const Homepage = lazy(() => import('../pages/Homepage'))

const ResidentialPropertyList = lazy(() =>
  import('../pages/ResidentialPage/ViewAllProperties'),
)
const AddResidentialProperty = lazy(() =>
  import('../pages/ResidentialPage/AddProperty'),
)
const ViewResidentialProperty = lazy(() =>
  import('../pages/ResidentialPage/ViewProperty'),
)

const CommercialPage = lazy(() =>
  import('../pages/CommercialPage/ViewAllProperties'),
)
const AddCommercialProperty = lazy(() =>
  import('../pages/CommercialPage/AddProperty'),
)

const ViewCommercialProperty = lazy(() =>
  import('../pages/CommercialPage/ViewProperty'),
)

const ViewOwnerLeads = lazy(() => import('../pages/WebsiteLeads/OwnerLeads'))

const ViewTenantLeads = lazy(() => import('../pages/WebsiteLeads/TenantLeads'))

const ViewServices = lazy(() => import('../pages/WebsiteLeads/Services'))

const AddBlog = lazy(() => import('../pages/BlogPage/AddBlog'))
const BlogList = lazy(() => import('../pages/BlogPage/BlogList'))
const RemoveBlog = lazy(() => import('../pages/BlogPage/RemoveBlog'))
const BlogDetails = lazy(() => import('../pages/BlogPage/BlogDetails'))

const TenantLeadList = lazy(() =>
  import('../pages/TenantLeadPage/ViewAllLeads'),
)

const AddTenantLead = lazy(() => import('../pages/TenantLeadPage/AddLead'))
const ViewTenantLead = lazy(() => import('../pages/TenantLeadPage/ViewLead'))
const ViewPayment = lazy(() => import('../pages/TenantLeadPage/ViewPayment'))

const TenantCommercialLeadList = lazy(() =>
  import('../pages/TenantCommercialLeadPage/ViewAllLeads'),
)
const AddTenantCommercialLead = lazy(() =>
  import('../pages/TenantCommercialLeadPage/AddLead'),
)
const ViewTenantCommercialLead = lazy(() =>
  import('../pages/TenantCommercialLeadPage/ViewLead'),
)
const ViewCurrentTenant = lazy(() =>
  import('../pages/TenantLeadPage/ViewCurrentTenant'),
)

const MobilePropertiesList = lazy(() =>
  import('../pages/MobilePropertiesPage/ViewAllProperties'),
)

const MobilePropertiesDetails = lazy(() =>
  import('../pages/MobilePropertiesPage/ViewProperty'),
)

const MobileFeedbackList = lazy(() =>
  import('../pages/MobileFeedbackPage/ViewAll'),
)

const MobileUserList = lazy(() => import('../pages/MobileUsersPage/ViewAll'))

const MobileUserDetails = lazy(() =>
  import('../pages/MobileUsersPage/ViewDetails'),
)

const VisitBookingList = lazy(() => import('../pages/VisitBooking/ViewAll'))

const contactUsList = lazy(() => import('../pages/ContactUs/ViewAll'))

const TenantRegistration = lazy(() => import('../pages/TenantRegistration'))

const TenantDashboard = lazy(() => import('../pages/TenantDashboard'))

const OnboardingTenant = lazy(() => import('../pages/OnboardingTenant'))

const OnboardingTenantList = lazy(() =>
  import('../pages/OnboardingTenant/ViewList'),
)

const OnboardingTenantDetails = lazy(() =>
  import('../pages/OnboardingTenant/ViewDetails'),
)

const OnboardingTenantPaymentList = lazy(() =>
  import('../pages/OnboardingTenant/PaymentList'),
)

const TenantPayments = lazy(() => import('../pages/TenantPayments'))
// const CommercialDetailsHome = lazy(() =>
// 	import('../pages/CommercialPage/CommercialDetailsHome')
// )
// const CommercialDetailsAddress = lazy(() =>
// 	import('../pages/CommercialPage/CommercialDetailsAddress')
// )
// const CommercialDetailsAmenities = lazy(() =>
// 	import('../pages/CommercialPage/CommercialDetailsAmenities')
// )
// const CommercialDetailsGallery = lazy(() =>
// 	import('../pages/CommercialPage/CommercialDetailsUpload')
// )

const Routes = () => {
  const RoutesList = [
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/',
      component: Homepage,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/residential-property/properties-list',
      component: ResidentialPropertyList,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/residential-property/add-property',
      component: AddResidentialProperty,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/residential-property/:propertyID',
      component: ViewResidentialProperty,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/commercial-property/properties-list',
      component: CommercialPage,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/commercial-property/add-property',
      component: AddCommercialProperty,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/commercial-property/:propertyID',
      component: ViewCommercialProperty,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/owner-leads',
      component: ViewOwnerLeads,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/tenant-leads',
      component: ViewTenantLeads,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/services',
      component: ViewServices,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/blog/add-blog',
      component: AddBlog,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/blog/blogs-list',
      component: BlogList,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/blog/remove-blog',
      component: RemoveBlog,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/blog/blog-details/:blogID',
      component: BlogDetails,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/lead/tenant-lead',
      component: AddTenantLead,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/lead/tenant-lead-list',
      component: TenantLeadList,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/lead/download-lead-list',
      component: TenantLeadList,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/lead/lead-details/:leadId',
      component: ViewTenantLead,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/lead/current-tenants/:leadId',
      component: ViewPayment,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/lead/tenant-commercial-lead',
      component: AddTenantCommercialLead,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/lead/tenant-commercial-lead-list',
      component: TenantCommercialLeadList,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/lead/lead-commercial-details/:leadId',
      component: ViewTenantCommercialLead,
      isPrivate: false,
    },
    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/lead/current-tenants/',
      component: ViewCurrentTenant,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/mobile/properties-list',
      component: MobilePropertiesList,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/mobile/property-details/:id',
      component: MobilePropertiesDetails,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/mobile/feedback-list',
      component: MobileFeedbackList,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/mobile/user-list',
      component: MobileUserList,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/mobile/user-details/:id',
      component: MobileUserDetails,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/bookings',
      component: VisitBookingList,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/contacts',
      component: contactUsList,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/tenant-registration',
      component: TenantRegistration,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/tenant-dashboard',
      component: TenantDashboard,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/onboarding-tenant/:tenantId',
      component: OnboardingTenant,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/onboarding-tenant-list',
      component: OnboardingTenantList,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/onboarding-tenant-details/:id',
      component: OnboardingTenantDetails,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/onboarding-tenant-payment-list/:tenantId',
      component: OnboardingTenantPaymentList,
      isPrivate: false,
    },

    {
      title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
      description:
        'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
      keywords: 'Property Management Services In Hyderabad',
      path: '/onboarded-tenants-payment-list',
      component: TenantPayments,
      isPrivate: false,
    },

    // {
    // 	title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
    // 	description:
    // 		'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
    // 	keywords: 'Property Management Services In Hyderabad',
    // 	path: '/commercial-properties/home/:id',
    // 	component: CommercialDetailsHome,
    // 	isPrivate: false,
    // },
    // {
    // 	title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
    // 	description:
    // 		'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
    // 	keywords: 'Property Management Services In Hyderabad',
    // 	path: '/commercial-properties/address/:id',
    // 	component: CommercialDetailsAddress,
    // 	isPrivate: false,
    // },
    // {
    // 	title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
    // 	description:
    // 		'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
    // 	keywords: 'Property Management Services In Hyderabad',
    // 	path: '/commercial-properties/amenities/:id',
    // 	component: CommercialDetailsAmenities,
    // 	isPrivate: false,
    // },
    // {
    // 	title: 'Best Property Management Services In Hyderabad | Owner & Tenant',
    // 	description:
    // 		'We are a property management company in Hyderabad, India. Our services include rental collection, maintenance and repair of residential and commercial properties. For more details visit us at http://www.ownerandtenant.com/',
    // 	keywords: 'Property Management Services In Hyderabad',
    // 	path: '/commercial-properties/upload/:id',
    // 	component: CommercialDetailsGallery,
    // 	isPrivate: false,
    // },
  ]

  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loaderImg">
            <Container>
              <Row mt-5>
                <Col>
                  <Spinner animation="grow" />
                </Col>
              </Row>
            </Container>

            {/* <img src='include/images/loader.gif' alt='' /> */}
          </div>
        }
      >
        {' '}
        <Switch onUpdate={() => window.scrollTo(0, 0)}>
          {RoutesList.map((item, routeIdx) => {
            return (
              <Route
                key={routeIdx}
                exact
                path={item.path}
                render={(props) => (
                  <SidebarContainer
                    {...props}
                    props={props}
                    component={item.component}
                    title={item.title}
                    description={item.description}
                    keywords={item.keywords}
                  />
                )}
              />
            )
          })}
        </Switch>
      </Suspense>
    </Fragment>
  )
}

export default Routes
