import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
  propertyListReducer,
  propertyDetailsReducer,
} from './reducers/propertyReducer'

import {
  downloadOwnerLeadsReducer,
  resedentialpropertyListReducer,
  residentialpropertyDetailsReducer,
} from './reducers/ResidentialPropertyReducer'

import { imageUploadReducer } from './reducers/propertyimageupload'

import { subscriptionReducer } from './reducers/miscReducer'
import {
  tenantEnquiryReducer,
  submitPropertyReducer,
  submitServiceReducer,
  submitContactUsReducer,
} from './reducers/enquiryReducer'

import {
  fetchTenantLeadsReducer,
  fetchOwnerLeadsReducer,
  fetchServicesLeadsReducer,
} from './reducers/websiteEnquiryReducers'

import {
  blogListReducer,
  blogDetailsReducer,
  blogSubmitReducer,
  blogRemoveReducer,
  blogEditReducer,
} from './reducers/blogReducer'

import {
  leadListReducer,
  leadDetailsReducer,
  leadSubmitReducer,
  leadEditReducer,
  downloadLeadsReducer,
} from './reducers/tenantLeadReducers'

import {
  leadCommercialListReducer,
  leadCommercialDetailsReducer,
  leadCommercialSubmitReducer,
  leadCommercialEditReducer,
} from './reducers/tenantCommercialLeadReducers'

import {
  propertiesListReducer,
  mobilePropertyDetailsReducer,
} from './reducers/mobilePropertiesReducers'

import {
  userListReducer,
  userDetailsReducer,
} from './reducers/mobileUserReducers'

import { feedbackListReducer } from './reducers/mobileFeedbackReducers'

import { authReducers, userSubmitReducer } from './reducers/authReducers'
import alertsReducer from './reducers/alertReducers'
import {
  commercialPropertyListReducer,
  singleUserReducer,
} from './reducers/commercialReducers'

import {
  bookingListReducer,
  bookingDetailsReducer,
  bookingEditReducer,
} from './reducers/visitBookingReducers'

import { contactUsListReducer } from './reducers/contactUsReducers'
import {
  onboardedTenantDetailsReducer,
  downloadTenantPaymentsReducer,
} from './reducers/onboardingTenantReducers'

const reducer = combineReducers({
  auth: authReducers,
  alerts: alertsReducer,
  propertyList: propertyListReducer,
  propertyDetails: propertyDetailsReducer,
  subscriptionEmail: subscriptionReducer,
  tenantEnquiryForm: tenantEnquiryReducer,
  submitPropertyForm: submitPropertyReducer,
  serviceEnquiryForm: submitServiceReducer,
  contactUsEnquiryForm: submitContactUsReducer,
  blogList: blogListReducer,
  blogDetails: blogDetailsReducer,
  residentialPropertyList: resedentialpropertyListReducer,
  residentialpropertyDetails: residentialpropertyDetailsReducer,
  propertyImageUpload: imageUploadReducer,
  commercialPropertyList: commercialPropertyListReducer,
  singleUser: singleUserReducer,
  fetchTenantLeads: fetchTenantLeadsReducer,
  fetchOwnerLeads: fetchOwnerLeadsReducer,
  fetchServicesLeads: fetchServicesLeadsReducer,
  blogSubmit: blogSubmitReducer,
  blogRemove: blogRemoveReducer,
  blogEdit: blogEditReducer,
  leadList: leadListReducer,
  dowloadLeadList: downloadLeadsReducer,
  leadDetails: leadDetailsReducer,
  leadSubmit: leadSubmitReducer,
  leadEdit: leadEditReducer,
  leadCommercialList: leadCommercialListReducer,
  leadCommercialDetails: leadCommercialDetailsReducer,
  leadCommercialSubmit: leadCommercialSubmitReducer,
  leadCommercialEdit: leadCommercialEditReducer,
  propertiesList: propertiesListReducer,
  mobilePropertyDetails: mobilePropertyDetailsReducer,
  feedbackList: feedbackListReducer,
  mobileUserList: userListReducer,
  mobileUserDetails: userDetailsReducer,
  bookingList: bookingListReducer,
  bookingDetails: bookingDetailsReducer,
  bookingEdit: bookingEditReducer,
  contactUsList: contactUsListReducer,
  downloadOwnerLeadsList: downloadOwnerLeadsReducer,
  signUp: userSubmitReducer,
  onboardingTenantDetails: onboardedTenantDetailsReducer,
  downloadTenantsPayments: downloadTenantPaymentsReducer,
})

const middleware = [thunk]

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
)

export default store
