import {
  SUBMIT_ONBOARDING_TENANT_REQUEST,
  SUBMIT_ONBOARDING_TENANT_SUCCESS,
  SUBMIT_ONBOARDING_TENANT_FAIL,
  ONBOARDING_TENANT_LIST_REQUEST,
  ONBOARDING_TENANT_LIST__SUCCESS,
  ONBOARDING_TENANT_LIST__FAIL,
  ONBOARDING_TENANT_DETAILS_REQUEST,
  ONBOARDING_TENANT_DETAILS__SUCCESS,
  ONBOARDING_TENANT_DETAILS__FAIL,
  DOWNLOAD_TENANT_PAYMENT_REQUEST,
  DOWNLOAD_TENANT_PAYMENT_SUCCESS,
  DOWNLOAD_TENANT_PAYMENT_FAIL,
  DOWNLOAD_TENANT_LIST_REQUEST,
  DOWNLOAD_TENANT_LIST_SUCCESS,
  DOWNLOAD_TENANT_LIST_FAIL,
  DOWNLOAD_TENANTS_PAYMENTS_REQUEST,
  DOWNLOAD_TENANTS_PAYMENTS_SUCCESS,
  DOWNLOAD_TENANTS_PAYMENTS_FAIL,
} from '../constants/onboardingTenantConstatnt'
const initialStateOnboardingTenantSubmit = {
  loading: false,
  status: null,
  success: null,
  error: null,
}

const initialStateTenants = {
  loading: false,
  onboardingtenants: [],
  error: null,
}

const initialStateTenant = {
  loading: false,
  onboardingtenant: {},
  error: null,
}

const initialStateTenantPayments = {
  loading: false,
  tenantpayments: [],
  error: null,
}

const initialStateTenantList = {
  loading: false,
  tenantlist: [],
  error: null,
}

const initialStateTenantsPayments = {
  loading: false,
  tenantspayments: [],
  error: null,
}

export const onboardingTenantSubmitReducer = (
  state = initialStateOnboardingTenantSubmit,
  action,
) => {
  switch (action.type) {
    case SUBMIT_ONBOARDING_TENANT_REQUEST:
      return { ...state, loading: true }
    case SUBMIT_ONBOARDING_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        success: action.payload.success,
      }
    case SUBMIT_ONBOARDING_TENANT_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
      }

    default:
      return state
  }
}

export const onboardedTenantListReducer = (
  state = initialStateTenants,
  action,
) => {
  switch (action.type) {
    case ONBOARDING_TENANT_LIST_REQUEST:
      return { ...state, loading: true, onboardingtenants: [] }
    case ONBOARDING_TENANT_LIST__SUCCESS:
      return { ...state, loading: false, onboardingtenants: action.payload }
    case ONBOARDING_TENANT_LIST__FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const onboardedTenantDetailsReducer = (
  state = initialStateTenant,
  action,
) => {
  switch (action.type) {
    case ONBOARDING_TENANT_DETAILS_REQUEST:
      return { ...state, loading: true, onboardingtenant: {} }
    case ONBOARDING_TENANT_DETAILS__SUCCESS:
      return { ...state, loading: false, onboardingtenant: action.payload }
    case ONBOARDING_TENANT_DETAILS__FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onboardingtenant: {},
      }

    default:
      return state
  }
}

export const downloadTenantPaymentsReducer = (
  state = initialStateTenantPayments,
  action,
) => {
  switch (action.type) {
    case DOWNLOAD_TENANT_PAYMENT_REQUEST:
      return { ...state, loading: true, tenantpayments: [] }
    case DOWNLOAD_TENANT_PAYMENT_SUCCESS:
      return { ...state, loading: false, tenantpayments: action.payload }
    case DOWNLOAD_TENANT_PAYMENT_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const downloadTenantListReducer = (
  state = initialStateTenantList,
  action,
) => {
  switch (action.type) {
    case DOWNLOAD_TENANT_LIST_REQUEST:
      return { ...state, loading: true, tenantlist: [] }
    case DOWNLOAD_TENANT_LIST_SUCCESS:
      return { ...state, loading: false, tenantlist: action.payload }
    case DOWNLOAD_TENANT_LIST_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const downloadTenantspaymentsReducer = (
  state = initialStateTenantsPayments,
  action,
) => {
  switch (action.type) {
    case DOWNLOAD_TENANTS_PAYMENTS_REQUEST:
      return { ...state, loading: true, tenanspayments: [] }
    case DOWNLOAD_TENANTS_PAYMENTS_SUCCESS:
      return { ...state, loading: false, tenanspayments: action.payload }
    case DOWNLOAD_TENANTS_PAYMENTS_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
