import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOAD_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
} from '../constants/authConstants'

import { localStorageToken, localStorageUser } from '../config/localStorage'

const initialState = {
  token: localStorage.getItem(localStorageToken),
  isAuthenticated: null,
  loading: null,
  user: localStorage.getItem(localStorageUser),
}
const initialStateUserSubmit = {
  loading: false,
  status: null,
  success: null,
  error: null,
}

export const authReducers = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case USER_LOAD_SUCCESS:
      return {
        ...state,
        isAuthenticated: payload.isAuthenticated,
        loading: false,
        user: localStorage.getItem(localStorageToken, payload.user),
      }

    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOGIN_SUCCESS:
      localStorage.setItem(localStorageToken, payload.token)
      localStorage.setItem(localStorageUser, payload.user)
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
        user: payload.user,
      }

    case USER_LOAD_FAILURE:
    case LOGIN_FAILURE:
    case LOGOUT:
      localStorage.removeItem(localStorageToken)
      localStorage.removeItem(localStorageUser)
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      }
    default:
      return state
  }
}

export const userSubmitReducer = (state = initialStateUserSubmit, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return { ...state, loading: true }
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        success: action.payload.success,
      }
    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
      }

    default:
      return state
  }
}
