export const LEAD_LIST_REQUEST = "LEAD_LIST_REQUEST";
export const LEAD_LIST_SUCCESS = "LEAD_LIST_SUCCESS";
export const LEAD_LIST_FAIL = "LEAD_LIST_FAIL";

export const LEAD_DETAILS_REQUEST = "LEAD_DETAILS_REQUEST";
export const LEAD_DETAILS_SUCCESS = "LEAD_DETAILS_SUCCESS";
export const LEAD_DETAILS_FAIL = "LEAD_DETAILS_FAIL";

export const SUBMIT_LEAD_REQUEST = "SUBMIT_LEAD_REQUEST";
export const SUBMIT_LEAD_SUCCESS = "SUBMIT_LEAD_SUCCESS";
export const SUBMIT_LEAD_FAIL = "SUBMIT_LEAD_FAIL";

export const EDIT_LEAD_REQUEST = "EDIT_LEAD_REQUEST";
export const EDIT_LEAD_SUCCESS = "EDIT_LEAD_SUCCESS";
export const EDIT_LEAD_FAIL = "EDIT_LEAD_FAIL";

export const DOWNLOAD_LEAD_REQUEST = "DOWNLOAD_LEAD_REQUEST";
export const DOWNLOAD_LEAD_SUCCESS = "DOWNLOAD_LEAD_SUCCESS";
export const DOWNLOAD_LEAD_FAIL = "DOWNLOAD_LEAD_FAIL";
