import {
  VISIT_BOOKING_LIST_REQUEST,
  VISIT_BOOKING_LIST_SUCCESS,
  VISIT_BOOKING_LIST_FAIL,
  VISIT_BOOKING_DETAILS_REQUEST,
  VISIT_BOOKING_DETAILS_SUCCESS,
  VISIT_BOOKING_DETAILS_FAIL,
  EDIT_BOOKING_DETAILS_REQUEST,
  EDIT_BOOKING_DETAILS_SUCCESS,
  EDIT_BOOKING_DETAILS_FAIL,
} from '../constants/visitBookingConstants'

const initialStateBookings = {
  loading: false,
  bookings: [],
  error: null,
}

const initialStateBooking = {
  loading: false,
  booking: {},
  error: null,
}

const initialStateBookingSubmit = {
  loading: false,
  status: null,
  success: null,
  error: null,
}

export const bookingListReducer = (state = initialStateBookings, action) => {
  switch (action.type) {
    case VISIT_BOOKING_LIST_REQUEST:
      return { ...state, loading: true, bookings: [] }
    case VISIT_BOOKING_LIST_SUCCESS:
      return { ...state, loading: false, bookings: action.payload }
    case VISIT_BOOKING_LIST_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const bookingDetailsReducer = (state = initialStateBooking, action) => {
  switch (action.type) {
    case VISIT_BOOKING_DETAILS_REQUEST:
      return { ...state, loading: true, booking: {} }
    case VISIT_BOOKING_DETAILS_SUCCESS:
      return { ...state, loading: false, booking: action.payload }
    case VISIT_BOOKING_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload, booking: {} }

    default:
      return state
  }
}

export const bookingEditReducer = (
  state = initialStateBookingSubmit,
  action,
) => {
  switch (action.type) {
    case EDIT_BOOKING_DETAILS_REQUEST:
      return { ...state, loading: true }
    case EDIT_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
        success: action.payload,
      }
    case EDIT_BOOKING_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
      }

    default:
      return state
  }
}
