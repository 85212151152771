import {
  CONTACTUS_LIST_REQUEST,
  CONTACTUS_LIST_SUCCESS,
  CONTACTUS_LIST_FAIL,
} from '../constants/contactUsConstants'

const initialStateContacts = {
  loading: false,
  contacts: [],
  error: null,
}

export const contactUsListReducer = (state = initialStateContacts, action) => {
  switch (action.type) {
    case CONTACTUS_LIST_REQUEST:
      return { ...state, loading: true, contacts: [] }
    case CONTACTUS_LIST_SUCCESS:
      return { ...state, loading: false, contacts: action.payload }
    case CONTACTUS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
