import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
} from '../constants/mobileConstants'

const initialStateUsers = {
  loading: false,
  users: [],
  error: null,
}

const initialStateUser = {
  loading: false,
  user: [],
  error: null,
}

export const userListReducer = (state = initialStateUsers, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { ...state, loading: true, users: [] }
    case USER_LIST_SUCCESS:
      return { ...state, loading: false, users: action.payload }
    case USER_LIST_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const userDetailsReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true, user: {} }
    case USER_DETAILS_SUCCESS:
      return { ...state, loading: false, user: action.payload }
    case USER_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload, user: {} }

    default:
      return state
  }
}
