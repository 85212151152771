import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Routes from "./routes/Routes";

import { useDispatch, useSelector } from "react-redux";
import { loadAccess } from "./actions/authActions";

import Signin from "./pages/Signin/";

function App(props) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!auth.isAuthenticated) {
      dispatch(loadAccess());
    }
  }, [dispatch, auth.isAuthenticated]);
  return (
    <Fragment>
      <Router>
        <Fragment>
          <Route path="/" component={auth.isAuthenticated ? Routes : Signin} />
        </Fragment>
      </Router>
    </Fragment>
  );
}

export default App;
