import {
  LEAD_LIST_REQUEST,
  LEAD_LIST_SUCCESS,
  LEAD_LIST_FAIL,
  LEAD_DETAILS_REQUEST,
  LEAD_DETAILS_SUCCESS,
  LEAD_DETAILS_FAIL,
  SUBMIT_LEAD_REQUEST,
  SUBMIT_LEAD_SUCCESS,
  SUBMIT_LEAD_FAIL,
  EDIT_LEAD_REQUEST,
  EDIT_LEAD_SUCCESS,
  EDIT_LEAD_FAIL,
  DOWNLOAD_LEAD_REQUEST,
  DOWNLOAD_LEAD_SUCCESS,
  DOWNLOAD_LEAD_FAIL,
} from "../constants/tenantLeadConstant";

const initialStateLeads = {
  loading: false,
  leads: [],
  error: null,
};

const initialStateLead = {
  loading: false,
  lead: {},
  error: null,
};

const initialStateLeadSubmit = {
  loading: false,
  status: null,
  success: null,
  error: null,
};

export const leadListReducer = (state = initialStateLeads, action) => {
  switch (action.type) {
    case LEAD_LIST_REQUEST:
      return { ...state, loading: true, leads: [] };
    case LEAD_LIST_SUCCESS:
      return { ...state, loading: false, leads: action.payload };
    case LEAD_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const leadDetailsReducer = (state = initialStateLead, action) => {
  switch (action.type) {
    case LEAD_DETAILS_REQUEST:
      return { ...state, loading: true, lead: {} };
    case LEAD_DETAILS_SUCCESS:
      return { ...state, loading: false, lead: action.payload };
    case LEAD_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload, lead: {} };

    default:
      return state;
  }
};

export const leadSubmitReducer = (state = initialStateLeadSubmit, action) => {
  switch (action.type) {
    case SUBMIT_LEAD_REQUEST:
      return { ...state, loading: true };
    case SUBMIT_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        success: action.payload.success,
      };
    case SUBMIT_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export const leadEditReducer = (state = initialStateLeadSubmit, action) => {
  switch (action.type) {
    case EDIT_LEAD_REQUEST:
      return { ...state, loading: true };
    case EDIT_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
        success: action.payload,
      };
    case EDIT_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export const downloadLeadsReducer = (state = initialStateLeads, action) => {
  switch (action.type) {
    case DOWNLOAD_LEAD_REQUEST:
      return { ...state, loading: true, leads: [] };
    case DOWNLOAD_LEAD_SUCCESS:
      return { ...state, loading: false, leads: action.payload };
    case DOWNLOAD_LEAD_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
