import {
	PROPERTY_LIST_REQUEST,
	PROPERTY_LIST_SUCCESS,
	PROPERTY_LIST_FAIL,
	PROPERTY_DETAILS_REQUEST,
	PROPERTY_DETAILS_SUCCESS,
	PROPERTY_DETAILS_FAIL,
} from '../constants/propertiesConstant'

const initialStateProperties = {
	loading: false,
	properties: [],
	error: null,
}

const initialStateProperty = {
	loading: false,
	property: {},
	error: null,
}

export const propertyListReducer = (state = initialStateProperties, action) => {
	switch (action.type) {
		case PROPERTY_LIST_REQUEST:
			return {...state, loading: true, properties: []}
		case PROPERTY_LIST_SUCCESS:
			return {...state, loading: false, properties: action.payload}
		case PROPERTY_LIST_FAIL:
			return {...state, loading: false, error: action.payload}

		default:
			return state
	}
}

export const propertyDetailsReducer = (
	state = initialStateProperty,
	action
) => {
	switch (action.type) {
		case PROPERTY_DETAILS_REQUEST:
			return {...state, loading: true, property: {}}
		case PROPERTY_DETAILS_SUCCESS:
			return {...state, loading: false, property: action.payload}
		case PROPERTY_DETAILS_FAIL:
			return {...state, loading: false, error: action.payload, property: {}}

		default:
			return state
	}
}
