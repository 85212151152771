export const LEAD_COMMERCIAL_LIST_REQUEST = "LEAD_COMMERCIAL_LIST_REQUEST";
export const LEAD_COMMERCIAL_LIST_SUCCESS = "LEAD_COMMERCIAL_LIST_SUCCESS";
export const LEAD_COMMERCIAL_LIST_FAIL = "LEAD_COMMERCIAL_LIST_FAIL";

export const LEAD_COMMERCIAL_DETAILS_REQUEST =
  "LEAD_COMMERCIAL_DETAILS_REQUEST";
export const LEAD_COMMERCIAL_DETAILS_SUCCESS =
  "LEAD_COMMERCIAL_DETAILS_SUCCESS";
export const LEAD_COMMERCIAL_DETAILS_FAIL = "LEAD_COMMERCIAL_DETAILS_FAIL";

export const SUBMIT_COMMERCIAL_LEAD_REQUEST = "SUBMIT_COMMERCIAL_LEAD_REQUEST";
export const SUBMIT_COMMERCIAL_LEAD_SUCCESS = "SUBMIT_COMMERCIAL_LEAD_SUCCESS";
export const SUBMIT_COMMERCIAL_LEAD_FAIL = "SUBMIT_COMMERCIAL_LEAD_FAIL";

export const EDIT_COMMERCIAL_LEAD_REQUEST = "EDIT_COMMERCIAL_LEAD_REQUEST";
export const EDIT_COMMERCIAL_LEAD_SUCCESS = "EDIT_COMMERCIAL_LEAD_SUCCESS";
export const EDIT_COMMERCIAL_LEAD_FAIL = "EDIT_COMMERCIAL_LEAD_FAIL";
