import React from 'react'
import { Container, Nav, NavDropdown } from 'react-bootstrap'
import { withRouter } from 'react-router'
import './Sidebar.css'

const Side = (props) => {
  return (
    <Container fluid className="main" style={{ backgroundColor: '#1D3557' }}>
      <Nav
        className="col-md-12 d-none d-md-block sidebar pl-2"
        style={{
          backgroundColor: '#1D3557',
          position: 'absolute',
          marginLeft: '1%',
        }}
        activeKey="/"
      >
        <div
          style={{
            marginLeft: '15%',
            fontWeight: 'bold',
            fontSize: '25px',
            color: 'white',
          }}
        >
          Admin Portal
        </div>
        <hr />
        {/* <button
            style={{
              marginLeft: "10%",
              marginBottom: "15%",
              padding: "10px 45px",
              borderWidth: "0rem",
              fontWeight: "bold",
              fontSize: "16px",
              color: "white",
              backgroundColor: "#e63946",
              borderRadius: "30px",
            }}
          >
            Add Leads
          </button> */}

        <div className="sidebar-sticky"></div>
        <Nav.Item
          style={{
            marginLeft: '10%',
            borderColor: 'black',
            marginBottom: '2%',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          <Nav.Link style={{ color: '#A4A6B3', fontSize: '0.8rem' }} href="/">
            DASHBOARD
          </Nav.Link>
        </Nav.Item>
        <hr />
        <NavDropdown
          //className="colorWhite"
          style={{
            color: 'white',
            marginLeft: '10%',
            borderColor: 'black',
            borderWidth: '1px',
            marginBottom: '2%',
            fontSize: '0.8rem',
            textAlign: 'center',
          }}
          title="TENANT LEADS"
          id="nav-dropdown"
        >
          <NavDropdown
            //className="colorWhite"
            style={{
              color: 'white',
              marginLeft: '10%',
              borderColor: 'black',
              borderWidth: '1px',
              marginBottom: '2%',
              fontSize: '0.8rem',
            }}
            title="Residential"
            //id="nav-dropdown"
          >
            <NavDropdown.Item
              href="/lead/tenant-lead-list"
              style={{ color: 'black' }}
              eventKey="4.1"
            >
              View All
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/lead/tenant-lead"
              eventKey="4.2"
              style={{ color: 'black' }}
            >
              Add Lead
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/lead/current-tenants"
              eventKey="4.2"
              style={{ color: 'black' }}
            >
              Current Tenants
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            //className="colorWhite"
            style={{
              color: 'white',
              marginLeft: '10%',
              borderColor: 'black',
              borderWidth: '1px',
              marginBottom: '2%',
              fontSize: '0.8rem',
            }}
            title="Commercial"
            //id="nav-dropdown"
          >
            <NavDropdown.Item
              href="/lead/tenant-commercial-lead-list"
              style={{ color: 'black' }}
              eventKey="4.1"
            >
              View All
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/lead/tenant-commercial-lead"
              eventKey="4.2"
              style={{ color: 'black' }}
            >
              Add Lead
            </NavDropdown.Item>
          </NavDropdown>
        </NavDropdown>
        <hr />
        <NavDropdown
          //className="colorWhite"
          style={{
            color: 'white',
            marginLeft: '10%',
            borderColor: 'black',
            borderWidth: '1px',
            marginBottom: '2%',
            fontSize: '0.8rem',
            textAlign: 'center',
          }}
          title="OWNER LEADS"
          id="nav-dropdown"
        >
          <NavDropdown
            //className="colorWhite"
            style={{
              color: 'white',
              marginLeft: '10%',
              borderColor: 'black',
              borderWidth: '1px',
              marginBottom: '2%',
              fontSize: '0.8rem',
            }}
            title="Residential"
            //id="nav-dropdown"
          >
            <NavDropdown.Item
              href="/residential-property/properties-list"
              style={{ color: 'black' }}
              eventKey="4.1"
            >
              View All
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/residential-property/add-property"
              eventKey="4.2"
              style={{ color: 'black' }}
            >
              Add Lead
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            //className="colorWhite"
            style={{
              color: 'white',
              marginLeft: '10%',
              borderColor: 'black',
              borderWidth: '1px',
              marginBottom: '2%',
              fontSize: '0.8rem',
            }}
            title="Commercial"
            //id="nav-dropdown"
          >
            <NavDropdown.Item
              href="/commercial-property/properties-list"
              style={{ color: 'black' }}
              eventKey="4.1"
            >
              View All
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/commercial-property/add-property"
              eventKey="4.2"
              style={{ color: 'black' }}
            >
              Add Lead
            </NavDropdown.Item>
          </NavDropdown>
        </NavDropdown>
        <hr />
        <NavDropdown
          style={{
            color: 'white',
            marginLeft: '10%',
            borderColor: 'black',
            borderWidth: '1px',
            marginBottom: '2%',
            fontSize: '0.8rem',
            textAlign: 'center',
          }}
          title="WEBSITE LEADS"
          id="nav-dropdown"
        >
          <NavDropdown.Item
            href="/tenant-leads"
            style={{ color: 'black' }}
            eventKey="4.1"
          >
            Tenant Leads
          </NavDropdown.Item>
          <NavDropdown.Item
            href="/owner-leads"
            eventKey="4.2"
            style={{ color: 'black' }}
          >
            Owner Leads
          </NavDropdown.Item>
        </NavDropdown>
        <hr />
        <Nav.Item
          style={{
            marginLeft: '10%',
            borderColor: 'black',
            marginBottom: '2%',
          }}
        >
          <Nav.Link
            style={{
              color: '#A4A6B3',
              fontSize: '0.8rem',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            href="/services"
          >
            SERVICES
          </Nav.Link>
        </Nav.Item>
        <hr />
        <Nav.Item
          style={{
            marginLeft: '10%',
            borderColor: 'black',
            marginBottom: '2%',
          }}
        >
          <Nav.Link
            style={{
              color: '#A4A6B3',
              fontSize: '0.8rem',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            href="/contacts"
          >
            CONTACTS
          </Nav.Link>
        </Nav.Item>
        <hr />
        <Nav.Item
          style={{
            marginLeft: '10%',
            borderColor: 'black',
            marginBottom: '2%',
          }}
        >
          <Nav.Link
            style={{
              color: '#A4A6B3',
              fontSize: '0.8rem',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            href=""
          >
            ACCOUNTS
          </Nav.Link>
        </Nav.Item>
        <hr />
        <Nav.Item
          style={{
            marginLeft: '10%',
            borderColor: 'black',
            marginBottom: '2%',
          }}
        >
          <Nav.Link
            style={{
              color: '#A4A6B3',
              fontSize: '0.8rem',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            href="/bookings"
          >
            VISIT BOOKINGS
          </Nav.Link>
        </Nav.Item>
        <hr />
        <NavDropdown
          style={{
            color: 'black',
            marginLeft: '10%',
            borderColor: 'black',
            borderWidth: '1px',
            marginBottom: '2%',
            fontSize: '0.8rem',
            textAlign: 'center',
          }}
          title="BLOGS"
          id="nav-dropdown"
        >
          <NavDropdown.Item
            href="/blog/blogs-list"
            eventKey="4.1"
            style={{ color: 'black' }}
          >
            View All
          </NavDropdown.Item>
          <NavDropdown.Item
            href="/blog/add-blog"
            eventKey="4.2"
            style={{ color: 'black' }}
          >
            Add Blog
          </NavDropdown.Item>
        </NavDropdown>
        <hr />
        <NavDropdown
          style={{
            color: 'white',
            marginLeft: '10%',
            borderColor: 'black',
            borderWidth: '1px',
            marginBottom: '2%',
            fontSize: '0.8rem',
            textAlign: 'center',
          }}
          title="MOBILE ADMIN"
          id="nav-dropdown"
        >
          <Nav.Item
            style={{
              marginLeft: '10%',
              color: 'black',
              marginBottom: '2%',
            }}
          >
            <Nav.Link href="/mobile/user-list">Users</Nav.Link>
          </Nav.Item>
          <Nav.Item
            style={{
              marginLeft: '10%',
              color: 'black',
              marginBottom: '2%',
            }}
          >
            <Nav.Link href="/mobile/properties-list">Properties</Nav.Link>
          </Nav.Item>

          <Nav.Item
            style={{
              marginLeft: '10%',
              color: 'black',
              marginBottom: '2%',
            }}
          >
            <Nav.Link href="/mobile/feedback-list">Feedbacks</Nav.Link>
          </Nav.Item>
        </NavDropdown>
        <hr />
        <NavDropdown
          style={{
            color: 'white',
            marginLeft: '10%',
            borderColor: 'black',
            borderWidth: '1px',
            marginBottom: '2%',
            fontSize: '0.8rem',
            textAlign: 'center',
          }}
          title="TENANT PORTAL"
          id="nav-dropdown"
        >
          <Nav.Item
            style={{
              marginLeft: '10%',
              color: 'black',
              marginBottom: '2%',
            }}
          >
            <Nav.Link href="/tenant-dashboard">Available Tenants</Nav.Link>
          </Nav.Item>
          <Nav.Item
            style={{
              marginLeft: '10%',
              color: 'black',
              marginBottom: '2%',
            }}
          >
            <Nav.Link href="/onboarding-tenant-list">
              Onboarded Tenants
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            style={{
              marginLeft: '10%',
              color: 'black',
              marginBottom: '2%',
            }}
          >
            <Nav.Link href="/onboarded-tenants-payment-list">
              Tenant Payments
            </Nav.Link>
          </Nav.Item>
        </NavDropdown>
      </Nav>
    </Container>
  )
}
const Sidebar = withRouter(Side)
export default Sidebar
