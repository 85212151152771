import React from 'react'
import { Fragment } from 'react'

import Sidebar from '../../components/Sidebar/'

import { Col, Container, Row } from 'react-bootstrap'
import './Sidebar.css'

const SideBar = (props) => {
  const PageComponent = props.component
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col sm={2} id="sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col sm={10}>
            <PageComponent {...props} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default SideBar
