export const USER_LOAD_REQUEST = 'USER_LOAD_REQUEST'
export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS'
export const USER_LOAD_FAILURE = 'USER_LOAD_FAILURE'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'
