import {
	SUBMIT_SUBSCRIPTION_REQUEST,
	SUBMIT_SUBSCRIPTION_SUCCESS,
	SUBMIT_SUBSCRIPTION_FAIL,
} from '../constants/miscConstant'

const initialStateSubscription = {
	loading: false,
	status: null,
	success: null,
	error: null,
}

export const subscriptionReducer = (
	state = initialStateSubscription,
	action
) => {
	switch (action.type) {
		case SUBMIT_SUBSCRIPTION_REQUEST:
			return {...state, loading: true}
		case SUBMIT_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				success: action.payload.success,
			}
		case SUBMIT_SUBSCRIPTION_FAIL:
			return {
				...state,
				loading: false,
				status: action.payload.status,
				error: action.payload.error,
			}

		default:
			return state
	}
}
