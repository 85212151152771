import {
  FEEDBACK_LIST_REQUEST,
  FEEDBACK_LIST_SUCCESS,
  FEEDBACK_LIST_FAIL,
} from '../constants/mobileConstants'

const initialStateFeedbacks = {
  loading: false,
  feedbacks: [],
  error: null,
}

export const feedbackListReducer = (state = initialStateFeedbacks, action) => {
  switch (action.type) {
    case FEEDBACK_LIST_REQUEST:
      return { ...state, loading: true, feedbacks: [] }
    case FEEDBACK_LIST_SUCCESS:
      return { ...state, loading: false, feedbacks: action.payload }
    case FEEDBACK_LIST_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
