import React, { Fragment, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { login } from "../../../actions/authActions";

const LoginForm = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    let isFormValid = true;

    if (password.length < 5) {
      isFormValid = false;
    }

    if (isFormValid) {
      dispatch(login(email, password));
    }
  };
  return (
    <Fragment>
      <Form
        className="justify-content-center"
        style={{ textAlign: "center" }}
        onSubmit={onSubmitHandler}
      >
        <Form.Group controlId="studentEmail" style={{ textAlign: "left" }}>
          <Form.Label>Login</Form.Label>
          <Form.Control
            name="email"
            value={email}
            onChange={onChangeHandler}
            type="email"
            placeholder="Enter email"
            className="inputEmail"
            required
          />
        </Form.Group>
        <br />

        <Form.Group controlId="studentPassword" style={{ textAlign: "left" }}>
          <Form.Label>Password</Form.Label>
          <Form.Control
            autoComplete={"false"}
            name="password"
            value={password}
            onChange={onChangeHandler}
            type="password"
            placeholder="Password"
            className="inputPassword"
            required
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="width-100 mt-3 loginButton"
          style={{ textAlign: "center" }}
        >
          Login
        </Button>
      </Form>
    </Fragment>
  );
};

export default LoginForm;
