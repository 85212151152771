export const BLOG_LIST_REQUEST = "BLOG_LIST_REQUEST";
export const BLOG_LIST_SUCCESS = "BLOG_LIST_SUCCESS";
export const BLOG_LIST_FAIL = "BLOG_LIST_FAIL";

export const BLOG_DETAILS_REQUEST = "BLOG_DETAILS_REQUEST";
export const BLOG_DETAILS_SUCCESS = "BLOG_DETAILS_SUCCESS";
export const BLOG_DETAILS_FAIL = "BLOG_DETAILS_FAIL";

export const SUBMIT_BLOG_REQUEST = "SUBMIT_BLOG_REQUEST";
export const SUBMIT_BLOG_SUCCESS = "SUBMIT_BLOG_SUCCESS";
export const SUBMIT_BLOG_FAIL = "SUBMIT_BLOG_FAIL";

export const REMOVE_BLOG_REQUEST = "REMOVE_BLOG_REQUEST";
export const REMOVE_BLOG_SUCCESS = "REMOVE_BLOG_SUCCESS";
export const REMOVE_BLOG_FAIL = "REMOVE_BLOG_FAIL";

export const EDIT_BLOG_REQUEST = "EDIT_BLOG_REQUEST";
export const EDIT_BLOG_SUCCESS = "EDIT_BLOG_SUCCESS";
export const EDIT_BLOG_FAIL = "EDIT_BLOG_FAIL";
