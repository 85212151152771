import {
  RESIDENTIAL_PROPERTY_LIST_FAIL,
  RESIDENTIAL_PROPERTY_LIST_REQUEST,
  RESIDENTIAL_PROPERTY_LIST_SUCCESS,
  RESIDENTIAL_PROPERTY_DETAILS_FAIL,
  RESIDENTIAL_PROPERTY_DETAILS_SUCCESS,
  RESIDENTIAL_PROPERTY_DETAILS_REQUEST,
  DOWNLOAD_OWNER_LEAD_REQUEST,
  DOWNLOAD_OWNER_LEAD_SUCCESS,
  DOWNLOAD_OWNER_LEAD_FAIL,
} from '../constants/ResidentialPropertyConstanst'

const initialStateProperties = {
  loading: false,
  properties: [],
  error: null,
}

const initialStateProperty = {
  loading: false,
  property: {},
  error: null,
}

const initialStateLeads = {
  loading: false,
  leads: [],
  error: null,
}

export const resedentialpropertyListReducer = (
  state = initialStateProperties,
  action,
) => {
  switch (action.type) {
    case RESIDENTIAL_PROPERTY_LIST_REQUEST:
      return { ...state, loading: true, properties: [] }
    case RESIDENTIAL_PROPERTY_LIST_SUCCESS:
      return { ...state, loading: false, properties: action.payload }
    case RESIDENTIAL_PROPERTY_LIST_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const residentialpropertyDetailsReducer = (
  state = initialStateProperty,
  action,
) => {
  switch (action.type) {
    case RESIDENTIAL_PROPERTY_DETAILS_REQUEST:
      return { ...state, loading: true, property: {} }
    case RESIDENTIAL_PROPERTY_DETAILS_SUCCESS:
      return { ...state, loading: false, property: action.payload }
    case RESIDENTIAL_PROPERTY_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload, property: {} }

    default:
      return state
  }
}

export const downloadOwnerLeadsReducer = (
  state = initialStateLeads,
  action,
) => {
  switch (action.type) {
    case DOWNLOAD_OWNER_LEAD_REQUEST:
      return { ...state, loading: true, leads: [] }
    case DOWNLOAD_OWNER_LEAD_SUCCESS:
      return { ...state, loading: false, leads: action.payload }
    case DOWNLOAD_OWNER_LEAD_FAIL:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
