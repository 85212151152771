export const RESIDENTIAL_PROPERTY_LIST_REQUEST =
  'RESIDENTIAL_PROPERTY_LIST_REQUEST'
export const RESIDENTIAL_PROPERTY_LIST_SUCCESS =
  'RESIDENTIAL_PROPERTY_LIST_SUCCESS'
export const RESIDENTIAL_PROPERTY_LIST_FAIL = 'RESIDENTIAL_PROPERTY_LIST_FAIL'

export const RESIDENTIAL_PROPERTY_DETAILS_REQUEST =
  'RESIDENTIAL_PROPERTY_DETAILS_REQUEST'
export const RESIDENTIAL_PROPERTY_DETAILS_SUCCESS =
  'RESIDENTIAL_PROPERTY_DETAILS_SUCCESS'
export const RESIDENTIAL_PROPERTY_DETAILS_FAIL =
  'RESIDENTIAL_PROPERTY_DETAILS_FAIL'

export const DOWNLOAD_OWNER_LEAD_REQUEST = 'DOWNLOAD_OWNER_LEAD_REQUEST'
export const DOWNLOAD_OWNER_LEAD_SUCCESS = 'DOWNLOAD_OWNER_LEAD_SUCCESS'
export const DOWNLOAD_OWNER_LEAD_FAIL = 'DOWNLOAD_OWNER_LEAD_FAIL'
