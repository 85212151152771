import {
  FETCH_TENANT_LEADS_REQUEST,
  FETCH_TENANT_LEADS_SUCCESS,
  FETCH_TENANT_LEADS_FAILURE,
  FETCH_OWNER_LEADS_REQUEST,
  FETCH_OWNER_LEADS_SUCCESS,
  FETCH_OWNER_LEADS_FAILURE,
  FETCH_SERVICE_LEADS_REQUEST,
  FETCH_SERVICE_LEADS_SUCCESS,
  FETCH_SERVICE_LEADS_FAILURE,
} from "../constants/websiteEnquiryConstants";

const initialStateTenant = {
  loading: false,
  leads: [],
  error: null,
  success: null,
};

const initialStateOwner = {
  loading: false,
  leads: [],
  error: null,
  success: null,
};
const initialStateServices = {
  loading: false,
  leads: [],
  error: null,
  success: null,
};
export const fetchTenantLeadsReducer = (state = initialStateTenant, action) => {
  switch (action.type) {
    case FETCH_TENANT_LEADS_REQUEST:
      return { ...state, loading: true, leads: [] };
    case FETCH_TENANT_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.payload.leads,
        success: action.payload.success,
        error: null,
      };
    case FETCH_TENANT_LEADS_FAILURE:
      return {
        ...state,
        loading: false,
        leads: [],
        success: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export const fetchOwnerLeadsReducer = (state = initialStateOwner, action) => {
  switch (action.type) {
    case FETCH_OWNER_LEADS_REQUEST:
      return { ...state, loading: true, leads: [] };
    case FETCH_OWNER_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.payload.leads,
        success: action.payload.success,
        error: null,
      };
    case FETCH_OWNER_LEADS_FAILURE:
      return {
        ...state,
        loading: false,
        leads: [],
        success: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export const fetchServicesLeadsReducer = (
  state = initialStateServices,
  action
) => {
  switch (action.type) {
    case FETCH_SERVICE_LEADS_REQUEST:
      return { ...state, loading: true, leads: [] };
    case FETCH_SERVICE_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.payload.leads,
        success: action.payload.success,
        error: null,
      };
    case FETCH_SERVICE_LEADS_FAILURE:
      return {
        ...state,
        loading: false,
        leads: [],
        success: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
