import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

// import 'react-phone-number-input/style.css'

import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import LoginForm from './components/LoginForm'

const StudentLogin = () => {
  const auth = useSelector((state) => state.auth)
  const { isAuthenticated } = auth

  if (isAuthenticated) {
    return <Redirect to="/" />
  } else {
    return (
      <div className="justify-content-center" style={{ height: '100vh' }}>
        <Container fluid>
          <Row>
            <Col lg={4}></Col>
            <Col lg={4} className="mt-5 mb-5 pt-3 pb-3">
              <Row>
                <Col className="mt-5 pt-5 pb-5 border border-radius-4 bg-white">
                  <div className="text-center">
                    <img
                      src="https://www.ownerandtenant.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdesktop-logo.25c4fe60.jpg&w=256&q=75"
                      alt="logo"
                      className="log-logo"
                      style={{ height: '50%', width: '50%' }}
                    />
                  </div>
                  <br />
                  <h1 className="pb-3 pt-2 text-center">Admin Login</h1>
                  <LoginForm />
                  <br />
                </Col>
              </Row>
            </Col>
            <Col lg={4}></Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default StudentLogin
